'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.locations.factory:LocationManager

 # @description

###
angular
  .module 'mundoAdmin.locations'
  .factory 'LocationManager', [
    'MundoDefaultManager'
    'TenantManager'
    'CountryManager'
    '$log'
    (
      MundoDefaultManager,
      TenantManager,
      CountryManager
      $log
    ) ->
      LocationManagerBase = new MundoDefaultManager()
      LocationManagerBase.setAutoTenant()
      LocationManagerBase.setUrl('locations')
      LocationManagerBase.setNewObject(['street', 'number', 'box', 'postalCode', 'city', 'countryCode'])
      LocationManagerBase.setSearchFields(
        ['street', 'number', 'box', 'postalCode', 'city', 'countryCode.value', 'tenant.label']
      )
      LocationManagerBase.defaultQuery.sort = 'postalCode'

      LocationManagerBase.getNewForm = ->
        [
          key: 'street'
          name: 'street'
          type: 'input'
          templateOptions:
            label: 'street'
            placeholder: 'street'
            required: true
        ,
          key: 'number'
          name: 'number'
          type: 'input'
          templateOptions:
            label: 'number'
            placeholder: 'number'
            required: true
        ,
          key: 'box'
          name: 'box'
          type: 'input'
          templateOptions:
            label: 'box'
            placeholder: 'box'
            required: false
        ,
          key: 'postalCode'
          name: 'postalCode'
          type: 'input'
          templateOptions:
            label: 'postalCode'
            placeholder: 'postalCode'
            required: true
        ,
          key: 'city'
          name: 'city'
          type: 'input'
          templateOptions:
            label: 'city'
            placeholder: 'city'
            required: true
        ,
          key: 'countryCode'
          name: 'countryCode'
          type: 'select'
          templateOptions:
            label: 'Country Code'
            placeholder: 'Country Code'
            required: true
            labelProp: "value"
            valueProp: "code"
            options: CountryManager.getListCustom()
        ]


      LocationManagerBase.getEditForm = (data)->
        [
          key: 'street'
          name: 'street'
          type: 'input'
          defaultValue: data.street
          templateOptions:
            label: 'street'
            placeholder: 'street'
            required: true
        ,
          key: 'number'
          name: 'number'
          type: 'input'
          defaultValue: data.number
          templateOptions:
            label: 'number'
            placeholder: 'number'
            required: true
        ,
          key: 'box'
          name: 'box'
          type: 'input'
          defaultValue: data.box
          templateOptions:
            label: 'box'
            placeholder: 'box'
            required: false
        ,
          key: 'postalCode'
          name: 'postalCode'
          type: 'input'
          defaultValue: data.postalCode
          templateOptions:
            label: 'postalCode'
            placeholder: 'postalCode'
            required: true
        ,
          key: 'city'
          name: 'city'
          type: 'input'
          defaultValue: data.city
          templateOptions:
            label: 'city'
            placeholder: 'city'
            required: true
        ,
          key: 'countryCode'
          name: 'countryCode'
          type: 'select'
          defaultValue: data.countryCode
          templateOptions:
            label: 'Country Code'
            placeholder: 'Country Code'
            required: true
            labelProp: "value"
            valueProp: "code"
            options: CountryManager.getListCustom()
        ]
      LocationManagerBase.getListSchema = ->
        [
          key: 'street'
          title: 'street'
          sort: 'street'
        ,
          key: 'number'
          title: 'number'
          sort: 'number'
        ,
          key: 'box'
          title: 'box'
          sort: 'box'
        ,
          key: 'postalCode'
          title: 'postalCode'
          sort: 'postalCode'
        ,
          key: 'city'
          title: 'city'
          sort: 'city'
        ,
          key: 'countryCode'
          title: 'countryCode'
          sort: 'countryCode'
  #       ,
  #         key: 'tenant.label'
  #         title: 'tenant'
  # #        sort: 'tenant.label'
        ]

      LocationManagerBase.editPermission = 'manage all MundoMosaContactBundle:Location entities'
      
      LocationManagerBase
  ]
